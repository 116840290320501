<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center min-vh-100"
  >
    <b-card class="text-center shadow-lg p-4" style="max-width: 400px;">
      <b-card-title>🎉 เปิดแอปของคุณ!</b-card-title>
      <b-card-text>
        คลิกปุ่มด้านล่างเพื่อเปิดแอปของคุณ หรือดาวน์โหลดจาก Store
      </b-card-text>

      <b-button
        variant="primary"
        class="mt-3 px-4 py-2 rounded-pill fw-bold shadow-sm"
        @click="openApp"
      >
        🚀 เปิดแอปของฉัน
      </b-button>

      <b-alert v-if="showAlert" variant="warning" class="mt-3" dismissible>
        ⚠️ ไม่พบแอปบนอุปกรณ์ของคุณ กำลังพาคุณไปที่ Store...
      </b-alert>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BDropdownItem,
  BCardTitle,
  BCardText,
  BAlert,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardTitle,
    BCardText,
    BAlert,
  },
  data() {
    return {
      showAlert: false,
      app: "",
      type: "",
      ref: "",
    };
  },
  methods: {
    openApp() {
      // storeapp://6417cda82cec207762a7bb78/deeplink?type%3Dregister%26ref%3D1234
      const scheme = `storeapp://${this.app}/deeplink?type%3D${this.type}%26ref%3D${this.ref}`; // แก้ไขให้เป็น deeplink ของแอปคุณ
      const appStore = `https://apps.apple.com/app/${this.app}`; // iOS Store
      const playStore = `https://play.google.com/store/apps/details?id=${this.app}`; // Android Store

      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isAndroid = /Android/.test(navigator.userAgent);
      const storeLink = isIOS ? appStore : isAndroid ? playStore : "";

      this.showAlert = false;
      window.location.href = scheme;

      setTimeout(() => {
        if (document.visibilityState === "visible") {
          this.showAlert = true;
          setTimeout(() => {
            window.location.href = storeLink;
          }, 1500); // ดีเลย์ก่อนพาไป Store
        }
      }, 2000); // รอ 2 วิ ดูว่ามีแอปหรือไม่
    },
  },
  created() {
    this.app = this.$route.query.app;
    this.type = this.$route.query.type;
    this.ref = this.$route.query.ref;
  },
};
</script>

<style scoped>
/* ปรับแต่งให้สวยขึ้น */
.shadow-lg {
  border-radius: 15px;
}
.rounded-pill {
  font-size: 1.2rem;
}
</style>
